import React, { ReactNode } from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { rhythm, scale } from "../../utils/typography";

import Img from "gatsby-image";

import LogoAgencjaOchronySowa from "../../images/partners-logo/logo_agencja_ochrony_sowa.inline.svg";
import LogoAkka from "../../images/partners-logo/logo_akka.inline.svg";
import LogoAvon from "../../images/partners-logo/logo_avon.inline.svg";
import LogoBacca from "../../images/partners-logo/logo_bacca.inline.svg";
import LogoEkoHouse from "../../images/partners-logo/logo_ekohouse.inline.svg";
import LogoFireFilm from "../../images/partners-logo/logo_fire_film.inline.svg";
import LogoGOT from "../../images/partners-logo/logo_got.inline.svg";
import LogoHevitech from "../../images/partners-logo/logo_hevitech.inline.svg";
import LogoIntelliVIX from "../../images/partners-logo/logo_intellivix.inline.svg";
import LogoIorto from "../../images/partners-logo/logo_iorto.inline.svg";
import LogoKolekcjaMadelskich from "../../images/partners-logo/logo_kolekcja_madelskich.inline.svg";
import LogoKonkursDokowicz from "../../images/partners-logo/logo_konkurs_dokowicz.inline.svg";
import LogoMeggot from "../../images/partners-logo/logo_meggot.inline.svg";
import LogoMetropoliaPoznan from "../../images/partners-logo/logo_metropolia_poznan.inline.svg";
import LogoNutritionLab from "../../images/partners-logo/logo_nutrition_lab.inline.svg";
import LogoPoznanArtWeek from "../../images/partners-logo/logo_poznan_art_week.inline.svg";
import LogoUAP from "../../images/partners-logo/logo_uniwersytet_artystyczny_w_poznaniu.inline.svg";
import LogoVMR from "../../images/partners-logo/logo_vmr.inline.svg";
import LogoWallers from "../../images/partners-logo/logo_wallers.inline.svg";
import LogoYES from "../../images/partners-logo/logo_yes.inline.svg";
import LogoYummypix from "../../images/partners-logo/logo_yummypix.inline.svg";
import LogoZielonePogotowie from "../../images/partners-logo/logo_zielone_pogotowie.inline.svg";
import LogoSWPS from "../../images/partners-logo/logo_swps.inline.svg";
import LogoBP from "../../images/partners-logo/logo_bp.inline.svg";
import LogoBudimex from "../../images/partners-logo/logo_budimex.inline.svg";

import "./customer-list.scss";

interface Props {}

class CustomerList extends React.Component {
  constructor(public props: Props) {
    super(props);
  }

  customerItems = [
    {
      name: "LogoAvon",
      src: <LogoAvon title="Logo firmy Avon" />,
    },
    {
      name: "LogoYES",
      src: <LogoYES title="Logo YES Biżuteria" />,
    },
    {
      name: "LogoBP",
      src: <LogoBP title="Logo Bp" />,
    },
    {
      name: "LogoMetropoliaPoznan",
      src: <LogoMetropoliaPoznan title="Logo Metropolia Poznań" />,
    },
    {
      name: "LogoBudimex",
      src: <LogoBudimex title="Logo Budimex" />,
    },
    {
      name: "LogoSWPS",
      src: <LogoSWPS title="Logo SWPS" />,
    },
    {
      name: "LogoZielonePogotowie",
      src: <LogoZielonePogotowie title="Logo Zielone Pogotowie" />,
    },
    {
      name: "LogoVMR",
      src: <LogoVMR title="Logo VMR" />,
    },
    {
      name: "LogoUAP",
      src: <LogoUAP title="Logo Uniwaersytetu Artystycznego w Poznaniu" />,
    },
    {
      name: "LogoKolekcjaMadelskich",
      src: <LogoKolekcjaMadelskich title="Logo Kolekcja Madelskich" />,
    },
    {
      name: "LogoPoznanArtWeek",
      src: <LogoPoznanArtWeek title="Logo Poznań Art Week" />,
    },
    {
      name: "LogoWallers",
      src: <LogoWallers title="Logo Wallers" />,
    },
    {
      name: "LogoBacca",
      src: <LogoBacca title="Logo firmy Bacca" />,
    },
    {
      name: "LogoHevitech",
      src: <LogoHevitech title="Logo Firmy Hevitech" />,
    },
    {
      name: "LogoMeggot",
      src: <LogoMeggot title="Logo Agencji Kreatywnej Meggot" />,
    },
    {
      name: "LogoAgencjaOchronySowa",
      src: <LogoAgencjaOchronySowa title="Logo Agencji Ochrony Sowa" />,
    },
    {
      name: "LogoAgencjaOchronySowa",
      src: <LogoAkka title="Logo Kancelarii Adwokackiej Andrzej Kulak" />,
    },
    {
      name: "LogoEkoHouse",
      src: <LogoEkoHouse title="Logo firmy EkoHouse" />,
    },
    {
      name: "LogoFireFilm",
      src: <LogoFireFilm title="Logo firmy Fire Film" />,
    },
    {
      name: "LogoGOT",
      src: <LogoGOT title="Logo Gorzowskiego Ośrodka Technologicznego" />,
    },
    {
      name: "LogoIntelliVIX",
      src: <LogoIntelliVIX title="Logo Firmy IntelliVIX" />,
    },
    {
      name: "LogoIorto",
      src: <LogoIorto title="Logo Firmy Iorto" />,
    },
    {
      name: "LogoKonkursDokowicz",
      src: <LogoKonkursDokowicz title="Logo Konkurs Dokowicz" />,
    },
    {
      name: "LogoNutritionLab",
      src: <LogoNutritionLab title="Logo Nutrition Lab" />,
    },
    {
      name: "LogoYummypix",
      src: <LogoYummypix title="Logo Yummypix" />,
    },
  ];

  render() {
    return (
      <div className="customer-wrapper">
        <ul className="r :grow :c-3 :c-4:md- :c-6:xs- :w :a-ce ">
          {this.customerItems.map((item, index) => {
            return (
              <li key={index} className="r :c-12 :a-cc ">
                <div className="wrapper">{item.src}</div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default CustomerList;
