import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { rhythm, scale } from "../../utils/typography";
import Slider from "react-slick";

import Img from "gatsby-image";
// import BackgroundImage from "gatsby-background-image";

import ImgLogoYes from "../../images/partners-logo/logo_yes.svg";
import ImgLogoSWPS from "../../images/partners-logo/logo_swps.svg";
import ImgLogoUap from "../../images/partners-logo/logo_uniwersytet_artystyczny_w_poznaniu.svg";
import ImgLogoTarget from "../../images/partners-logo/logo_targt.svg";
import ImgLogoZielonePogotowie from "../../images/partners-logo/logo_zielone_pogotowie.svg";
import ImgLogoSimpliCity from "../../images/partners-logo/logo_simplicity.svg";
import ImgLogoMeggot from "../../images/partners-logo/logo_meggot.svg";

import "./references.scss";

interface Props {}

class References extends React.Component {
  settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    pauseOnHover: true,
    pauseOnFocus: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchThreshold: 25,
  };
  constructor(public props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="references-wrapper">
          <div className="references-container">
            <Slider {...this.settings}>
              <div className="item">
                <p className="content">
                  Z&nbsp;przyjemnością rekomenduję współpracę z&nbsp;Agencją
                  interaktywną APLINET, jako firma godną polecenia
                  w&nbsp;zakresie wdrażania portali internetowych araz aplikacji
                  z&nbsp;dedykowanym autorskim systemem zarządzania. Firma
                  przygotowała stronę kolekcji sztuki www.madelskicollection.com
                  oraz oprogramowanie umożliwiające jej zarządznaniem
                  (wyszukiwanie, katalogowanie, archiwizację, CMS).
                </p>
                <p className="content">
                  Miło mi potwierdzić, że zaspół specjalistów z&nbsp;firmy
                  Aplinet, świadczy usługi na najwyższym poziomie. Z&nbsp;dużą
                  starannością podchodzi do wykonywanych czynności, proponując
                  innowacyjne rozwiązania, które spełniają wszystkie stawiane
                  wymagania. Jestem pełen zaufania dla wiedzy, umiejętności
                  i&nbsp;profesjonalizmu.
                </p>
                <div className="reference-footer r :c-12 :c-6:xxs+ :a-cc :a-ce:xxs+ :w">
                  <div>
                    <p className="signature">
                      <span className="person" style={{ color: "#096a61" }}>
                        Krzysztof Madelski
                      </span>
                      <br />
                      <span className="position">Co-owner YES</span>
                      <br />
                      <span className="company">YES Biżuteria</span>
                      <br />
                    </p>
                  </div>
                  <img src={ImgLogoYes} alt="Logo YES" className="logo-image" />
                </div>
              </div>
              <div className="item">
                <p className="content">
                  SWPS Uniwersytet Humanistycznospołeczny współpracował
                  z&nbsp;Agencją Interaktywną i&nbsp;Software Housem - APLINET
                  w&nbsp;okresie od kwietnia do sierpnia 2020&nbsp;r.
                </p>
                <p className="content">
                  Firma APLINET odpowiadała za zaprojektowanie
                  i&nbsp;opracownanie aplikacji webowej wraz z&nbsp;modułem
                  bazodanowym, tzw. dashboards oraz implementację
                  w&nbsp;aplikacji dwóch narzędzi badawczych, m.in.
                  kwestionariusza Milamind.
                </p>
                <p className="content">
                  Przy realizacji projektu, zespół programistów APLINET wzorowo
                  wykonał wszystkie zadania. Wykazał się rzetelnością oraz
                  wysokimi kompetencjami, trafnie sugerując rozwiązania
                  napotkanych problemów. Profesjonalizm i&nbsp;zaangażowanie
                  zespołu APLINET przełożyło się na efekty, które całkowicie
                  spełniły nasze oczekiwania.
                </p>
                <p className="content">
                  SWPS Uniwersytet Humanistycznospołeczny rekomenduje współpracę
                  z&nbsp;Agencją Interaktywną i&nbsp;Software Housem - APLINET.
                  Firma jest godna polecenia w&nbsp;zakresie projektowania,
                  wdrażania, administrowania i&nbsp;opieki echnicznej aplikacji
                  webowych.
                </p>
                <div className="reference-footer r :c-12 :c-6:xxs+ :a-cc :a-ce:xxs+ :w">
                  <div>
                    <p className="signature">
                      <span className="person" style={{ color: "#2f404d" }}>
                        Iwona Gawrycka
                      </span>
                      <br />
                      <span className="position">
                        Menedżer innowacji, Dyrektor Centrum Transferu Wiedzy
                      </span>
                      <br />
                      <span className="company">Uniwersytet SWPS</span>
                      <br />
                    </p>
                  </div>
                  <img
                    src={ImgLogoSWPS}
                    alt="Logo Uniwersytety SWPS"
                    className="logo-image"
                  />
                </div>
              </div>
              <div className="item">
                <p className="content">
                  Agencja Interaktywna Aplinet współpracuje z&nbsp;Zielonym
                  Pogotowiem od początku 2020r. Przedmiotem zleceń było m.in.
                  wykonanie i&nbsp;wdrożenie kluczowych funkcjonalności
                  aplikacji mobilnej Zielone Pogotowie App oraz udostępnienie
                  systemu do jej zarządzania. Firma Aplinet, dała się poznać,
                  jako solidny partner, realizujący powierzone zadania na
                  wysokim poziomie. Wykorzystuje nowe technologie i&nbsp;posiada
                  wykwalifikowany zespół specjalistów.
                </p>
                <p className="content">
                  Będąc bardzo zadowolonym z&nbsp;solidności wykonanych usług,
                  rekomenduję Aplinet jako wykonawcę prac programistycznych.
                </p>
                <div className="reference-footer r :c-12 :c-6:xxs+ :a-cc :a-ce:xxs+ :w">
                  <div>
                    <p className="signature">
                      <span className="person" style={{ color: "#820000" }}>
                        Przemysław Hirsch
                      </span>
                      <br />
                      <span className="position">Dyrektor Handlowy</span>
                      <br />
                      <span className="company">Tamark – Grupa Target</span>
                      <br />
                    </p>
                  </div>
                  <img
                    src={ImgLogoTarget}
                    alt="Logo Target"
                    className="logo-image"
                  />
                </div>
              </div>
              <div className="item">
                <p className="content">
                  Uniwersytet Artystyczny w&nbsp;Poznaniu poleca Agencję
                  Interaktywną APLINET jako godnego i&nbsp;solidnego partnera
                  w&nbsp;zakresie tworzenia stron internetowych.
                </p>
                <p className="content">
                  Witryny realizowane we współpracy z&nbsp;agencją, dostępne pod
                  adresami: www.poznanartweek.com, www.konkursdokowicz.pl,
                  www.sztukakomunikacji.uap.edu.pl, www.ideografia.pl, zostały
                  przygotowane w&nbsp;sposób profesjonalny. Zyskały design
                  i&nbsp;funkcjonalności, które spełniają wszystkie wymagania
                  i&nbsp;nawiązują do najlepszych praktyk tworzenia
                  oprogramowania.
                </p>
                <p className="content">
                  W&nbsp;trakcie realizacji zadań, pracownicy firmy wykazali się
                  kompetencjami, profesjonalizmem i&nbsp;rzetelnością. Prace
                  przebiegały zgodnie z&nbsp;wyznaczonym harmonogramem. Na
                  uznanie zasługuje również bardzo sprawnie działający system
                  CMS. Szkolenia z&nbsp;jego obsługi oraz pomoc przy
                  administrowaniu witrynami, zostały docenione przez pracowników
                  Biura Promocji UAP. Polecamy usługi Agencji Interaktywnej
                  Aplinet.
                </p>
                <div className="reference-footer r :c-12 :c-6:xxs+ :a-cc :a-ce:xxs+ :w">
                  <div>
                    <p className="signature">
                      <span className="person" style={{ color: "#009FE3" }}>
                        Piotr Grzywacz
                      </span>
                      <br />
                      <span className="position">
                        p.o. Kierownika Biura Promocji
                      </span>
                      <br />
                      <span className="company">
                        Uniwersytet Artystyczny w&nbsp;Poznaniu
                      </span>
                      <br />
                    </p>
                  </div>
                  <img
                    src={ImgLogoUap}
                    alt="Logo Uniwersytetu Artystycznego w Poznaniu"
                    className="logo-image"
                  />
                </div>
              </div>
              <div className="item">
                <p className="content">
                  Aplinet zaprojektowała wygląd i&nbsp;zaprogramowała Zielone
                  Pogotowie App. Aplikaję ogrodników dostępną na telefonach
                  i&nbsp;tabletach z&nbsp;systemami Android i&nbsp;iOS. Wraz
                  z&nbsp;rozwojem projektu, przygotowała portal Zielonego
                  Pogotowia na komputerach. Wdrożone rozwiązania umożliwiają nam
                  proste zarządzanie przy pomocą dedykowanego systemu CSM.
                  Pracownicy firmy, w&nbsp;dalszym ciągu rozwijają
                  i&nbsp;administrują nasz produkt.
                </p>
                <p className="content">
                  Aplinet to kompletna firma, która świadczy swoje usługi na
                  wysokim poziomie oraz podchodzi z&nbsp;zaangażowaniem do
                  potrzeb klienta. Polecamy Aplinet jako firmę wyróżniającą się
                  wysokim profesjonalizmem, terminowością oraz sumiennością
                  w&nbsp;realizowaniu zadań.
                </p>
                <div className="reference-footer r :c-12 :c-6:xxs+ :a-cc :a-ce:xxs+ :w">
                  <div>
                    <p className="signature">
                      <span className="person" style={{ color: "#8bc24a" }}>
                        Grzegorz Majda
                      </span>
                      <br />
                      <span className="position">Ogrodnik, właściciel</span>
                      <br />
                      <span className="company">Zielone Pogotowie</span>
                      <br />
                    </p>
                  </div>
                  <img
                    src={ImgLogoZielonePogotowie}
                    alt="Logo Zielone Pogotowie"
                    className="logo-image"
                  />
                </div>
              </div>
              <div className="item">
                <p className="content">
                  Firma Aplinet, była odpowiedzialna za realizację strony
                  internetowej www.curem.pl, promującej wprowadzane produkty.
                  Projekt wykreowany przez wykonawców, dzięki dużemu
                  profesjonalizmowi grafików, szczególnie w&nbsp;przygotowaniu
                  wizualizacji 3D, w&nbsp;całości spełnił oczekiwania klienta
                  głównego, Hilding Anders. Programiści sprawnie wprowadzili
                  wszystkie pomysły, przygotowując stronę zgodnie
                  z&nbsp;wymaganiami, co umożliwiło terminową publikację.
                </p>
                <p className="content">
                  Z&nbsp;powierzonych zadań agencja wywiązała się wzorowo.
                  Współpraca układała się pomyślnie a&nbsp;stworzona strona
                  internetowa spełniła nasze oczekiwania zarówno pod względem
                  wizualnym jak i&nbsp;funkcjonalnym. Polecamy usługi
                  programistyczne w&nbsp;firmie Aplinet.
                </p>
                <div className="reference-footer r :c-12 :c-6:xxs+ :a-cc :a-ce:xxs+ :w">
                  <div>
                    <p className="signature">
                      <span className="person" style={{ color: "#894b38" }}>
                        Jeremi Bryl
                      </span>
                      <br />
                      <span className="position">Dyrektor Handlowy</span>
                      <br />
                      <span className="company">
                        SimpliCity Printing House Sp. z&nbsp;o.o.
                      </span>
                      <br />
                    </p>
                  </div>
                  <img
                    src={ImgLogoSimpliCity}
                    alt="Logo Simpli City"
                    className="logo-image"
                  />
                </div>
              </div>
              <div className="item">
                <p className="content">
                  Jesteśmy bardzo zadowoleni z&nbsp;działań Agencji
                  Interaktywnej Aplinet, związanych z&nbsp;przygotowaniem,
                  administracją i&nbsp;obsługą sklepu internetowego WALLEERS
                  oraz naszej strony wwww.megoot.pl.
                </p>
                <p className="content">
                  W&nbsp;naszej współpracy cenimy sobie kreatywność rozwiązań
                  oraz wysoką jakość pracy. Z&nbsp;pełną odpowiedzialnością
                  możemy zarekomendować firmę jako rzetelnego
                  i&nbsp;sprawdzonego partnera do współpracy, gwarantującego
                  wysoką jakość usług.
                </p>
                <div className="reference-footer r :c-12 :c-6:xxs+ :a-cc :a-ce:xxs+ :w">
                  <div>
                    <p className="signature">
                      <span className="person" style={{ color: "#ffbb00" }}>
                        Jan Marcinowski
                      </span>
                      <br />
                      <span className="position">
                        Founder &&nbsp;Managing Director
                      </span>
                      <br />
                      <span className="company">Agencja Kreatywna MEGGOT</span>
                      <br />
                    </p>
                  </div>
                  <img
                    src={ImgLogoMeggot}
                    alt="Logo Meggot"
                    className="logo-image"
                  />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </>
    );
  }
}

export default References;
