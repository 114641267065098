import React, { Ref } from "react";
import Layout from "../../components/layout/layout";
import SEO from "../../components/seo/seo";
import { PageParams } from "../../../types";

import "./customers.scss";

import CallFooter from "../../components/call-footer/call-footer";
import HeaderBig from "../../components/header-big/header-big";
import Hero from "../../components/hero/hero";
import References from "../../components/references/references";
import Customers from "../../components/customers/customers";
import CustomerList from "../../components/customer-list/customer-list";
import Section from "../../components/air-section/air-section";
import BoxList from "../../components/box-list/box-list";

import ServiceIconWebsite from "../../images/offer/service_icon_website.svg";
import ScrollButton from "../../components/scroll-button/scroll-button";

class CustomersPage extends React.Component {
  language = "pl";
  scrollRef: Ref<HTMLElement> = null;
  constructor(public params: PageParams) {
    super(params);
    this.language = params.pageContext.language;
    this.scrollRef = React.createRef();
  }
  offerItems = [
    {
      name: "Opinie i&nbsp;rekomendacje",
      img: ServiceIconWebsite,
      alt: "Ikona rekomendacji wystawionych przez klientów",
      description:
        "Bardzo często słyszymy, że jesteśmy godni polecenia. Nasi klienci, oprócz wystawienia nam rekomendacji, polecają nasze usługi swoim partnerom biznesowym. Jest nam miło, że pozytywny wizerunek firmy pozwala przekonać do współpracy nowych kontrahentów.",
    },
    {
      name: "Portfolio",
      img: ServiceIconWebsite,
      alt: "Ikona portfolio agencji interaktywnej",
      description:
        "Analiza naszego portfolio umożliwia nie tylko sprawdzenie czym zajmowaliśmy się w&nbsp;przeszłości ale weryfikuje nasze doświadczenie i&nbsp;pokazuje stopień skomplikowania realizowanych projektów.",
    },
    {
      name: "Staż agencji",
      img: ServiceIconWebsite,
      alt: "Ikona staż agencji",
      description:
        "Doświadczenia nie można kupić... Wieloletnia obecność na rynku, pozwoli lepiej zrozumieć potrzeby i&nbsp;zaproponuje sprawdzone, skuteczne rozwiązania.",
    },
    {
      name: "Zespół",
      img: ServiceIconWebsite,
      alt: "",
      description:
        "Nasi klienci doceniają że możemy wykonać, usługę kompleksowo. W&nbsp; pracują wyspecjalizowane osoby, odpowiadające za określone dziedziny. Jesteśmy elastyczni i doświadczeni w wielu technologiach.",
    },
    {
      name: "Jasna forma współpracy",
      img: ServiceIconWebsite,
      alt: "Ikona",
      description:
        "Sprawdzone narzędzia do prowadzenia projektu, łatwy sposób komunikacji, przejżysta forma współpracy, terminowość. Te wszystkie cechy sprawiają, żę realizacja projektu przebiega bez problemów.",
    },

    {
      name: "Cena adekwatna do jakość",
      img: ServiceIconWebsite,
      alt: "",
      description:
        "W przypadku wiarygodnych agencji interaktywnych, zyskuje się większą pewność, że projekty zostaną zrealizowane w sposób staranny i sumienny. Warto zapytać nas o wycenę, może okazać się, że cena realizacji wcale nie jest wysoka...",
    },
  ];

  render() {
    return (
      <>
        <SEO
          title="Klienci"
          decription="Od 2013r zaufało nam wiele firm i instytucji dla których wykonaliśmy setki różnych realizacji. •Rekomendacje ☎ +48 665 035 451"
          lang={this.language}
        />
        <Hero>
          <div className="container-xs header-padding">
            <h1>Nasi Klineci</h1>
            <div className="header-subline">
              <h2 className="subtitle">
                Oceniliśmy jakość obsługi i&nbsp;satysfakcji naszych klientów,
                na podstawie wskaźnika Net Promoter Score (NPS).
              </h2>
            </div>
            <h4>
              Jesteśmy dumni z&nbsp;wyniku 54 punktów, który znacznie przewyższa
              średnią w&nbsp;branży.
            </h4>
          </div>
          <ScrollButton
            style={{
              position: "absolute",
              left: "50%",
              bottom: "5%",
              transform: "translateX(-50%)",
            }}
            to={this.scrollRef}
          />
        </Hero>

        <Section className="container" ref={this.scrollRef}>
          <HeaderBig
            tag="h2"
            span="Kto nas poleca?"
            headerText="Nasi klienci"
          />
          <CustomerList />
        </Section>

        <Section className="container">
          <p>
            Głos i&nbsp;opinia klientów są dla nas niezmiernie ważne, dlatego
            podejmujemy konkretne działania, żeby zyskać ich lojalność
            i&nbsp;podwyższyć satysfakcję. Klienci pytani o&nbsp;skłonność do
            polecania naszej Agencji biorą pod uwagę całość swoich doświadczeń,
            zarówno ocenę obsługi, ofertę oraz jej koszty.
          </p>
          <h4>
            Cieszymy się, że osiągnęliśmy jeden z&nbsp;najwyższych poziomów
            satysfakcji klienta w&nbsp;branży!
          </h4>
        </Section>

        <Section className="container offer-section">
          <HeaderBig
            tag="h2"
            span="Za co nas doceniono?"
            headerText="Największe atuty firmy"
          />
          <div className="r :w :g :c-12 :c-6:xs :c-6:sm :c-4:sm+">
            {this.offerItems.map((item, index) => {
              return (
                <div className="item-wrapper" key={index}>
                  <div className="item r :a-t :w :c-12 ">
                    <div className="image">
                      <img src={item.img} alt={item.alt} />
                      <div className="content">
                        <h3
                          className="name"
                          dangerouslySetInnerHTML={{
                            __html: item.name,
                          }}
                        ></h3>
                        <p
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: item.description,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Section>
        <Section backgroundColor="#000" className="container">
          <HeaderBig
            tag="h2"
            span="Kogo zainteresują nasze usługi?"
            headerText="Profil klientów"
            color="#50817b"
          />
          <BoxList
            boxItems={[
              {
                title: `Start UP'y, małe firmy i&nbsp;przedsiębiorstwa`,
                description:
                  "Tworzymy MVP zagranicznych startupów zbierając doświadczenie na międzynarodowym rynku IT. Przygotowaliśmy olbrzymią ilość produkty dla firm i&nbsp;przedsiębiorstw, które zaczynają dopiero swoją przygodę z&nbsp;marketingiem online. Pierwsze strony internetowe, foldery i&nbsp;wizytówka, pomogły rozwinąć skrzydła wszystkim, którzy nam zaufali.",
              },
              {
                title: `Duże firmy i&nbsp;Korporacje`,
                description:
                  "Rebranding, ukierunkowanie strategi, dalszy rozwój firmy a&nbsp;także świeże spojrzenie na dotychczasowe osiągnięcia oraz optymalizacja działania, pomogły zoptymalizować koszty wielu naszych współpracowników.",
              },
              {
                title: `Partnerzy`,
                description:
                  "Jesteśmy wsparciem dla liczących się agencji, zdecydowanie większych od naszej. Firmy, które pozytywnie oceniają nasze umiejętności to np: VMR, GPD, SimpliC, TFC, Garden of Words... </br> Dzięki możliwości takiej współpracy, wiemy jak ważna jest praca zespołu i&nbsp;potrafimy skutecznie działać przy realizacji dużych zleceń.",
              },
            ]}
          />
        </Section>
        <Section className="container" backgroundColor="whitesmoke">
          <HeaderBig
            tag="h2"
            span="Opinie Klientów"
            headerText="Nasze rekomendacie"
          />
          <References />
        </Section>
        <CallFooter />
      </>
    );
  }
}

export default CustomersPage;
